<style type="text/css">
@import "~@/assets/css/common.css";
.selectClass {
  width: 180px;
  margin-right: 20px;
}
</style>
<script>
import $ from "jquery";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {
  getExamList,
  getUserExamList,
  getHMDK,
  getWJK,
  userSpecial,
  getExclusive,
  getExamInfo,
} from "@/api/examination.js";
import { format, getUserInfo, getDictionary } from "@/libs/common";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "报名项目列表",
      items: [
        {
          text: "考试报名",
        },
        {
          text: "报名列表",
          active: true,
        },
      ],
      userInfo: {},
      postData: {
        ksnf: "",
      },
      nfList: [],
      bmztList: [
        { name: "全部", value: "" },
        { name: "未开始", value: "0" },
        { name: "正在报名", value: "1" },
        { name: "已结束", value: "2" },
      ],
      flList: [],
      lbList: [],
      examList: [],
      recordList: [],
      chooseIndex: "",
      specialList: [],
    };
  },
  methods: {
    getUserExam() {
      getUserExamList().then((res) => {
        if (res.status) {
          this.recordList = res.data;
          // this.getSpecial()
        }
      });
    },
    getSpecial() {
      return new Promise((resolve, reject) => {
        userSpecial().then((res) => {
          if (res.status) {
            let data = res.data;
            if (data && data.length) {
              this.specialList = data;
            }
            resolve();
          }
        });
      });
    },
    //检查考生是否在专有名单库
    exclusiveCheck(ksbmbh, callback) {
      getExclusive(ksbmbh).then((res) => {
        if (res.status) {
          if (res.data[0]) {
            callback(true);
          } else {
            callback(false);
          }
        }
      });
    },
    //检测考生是否在黑名单库/违纪库中
    qualification(ksbmbh) {
      return new Promise((resolve, reject) => {
        let data = this.examList[this.chooseIndex];
        if (data.sfqyhmdk) {
          // 判断是否在黑名单库
          getHMDK(ksbmbh).then((res) => {
            if (res.status) {
              if (res.data && res.data.length != 0) {
                this.$message({
                  type: "error",
                  message: "对不起，您不具备报名资格",
                });
              } else {
                if (data.sfqywjk) {
                  // 判断是否在违纪库
                  getWJK(ksbmbh).then((res) => {
                    if (res.status) {
                      if (res.data && res.data.length != 0) {
                        let data = res.data[res.data.length - 1];
                        this.$message({
                          type: "error",
                          message:
                            "对不起，您不具备报名资格,原因：" +
                            data.ksmc +
                            data.wjyy,
                        });
                        resolve(false);
                      } else {
                        resolve(true);
                      }
                    }
                  });
                } else {
                  resolve(true);
                }
              }
            } else {
              resolve(false);
            }
          });
        } else if (data.sfqywjk) {
          // 判断是否在违纪库
          getWJK(ksbmbh).then((res) => {
            if (res.status) {
              if (res.data && res.data.length != 0) {
                let data = res.data[res.data.length - 1];
                this.$message({
                  type: "error",
                  message:
                    "对不起，您不具备报名资格,原因：" + data.ksmc + data.wjyy,
                });
                resolve(false);
              } else {
                resolve(true);
              }
            }
          });
        } else {
          resolve(true);
        }
      });
    },
    //检测考生是否在考试特殊库中
    checkSpecial(data) {
      return new Promise((resolve, reject) => {
        if (data.ksbmzt_ != "正在报名" && data.sfqytsmdk) {
          if (this.specialList.length) {
            let specialData = this.specialList.filter((v) => {
              return v.ksbmbh == data.ksbmbh;
            })[0];
            if (!specialData) {
              this.$message({
                type: "error",
                message: "你未在考试特殊名单库中，无法报名",
              });
              resolve(false);
            }
            resolve(true);
          } else {
            this.getSpecial().then(() => {
              let specialData = this.specialList.filter((v) => {
                return v.ksbmbh == data.ksbmbh;
              })[0];
              if (!specialData) {
                this.$message({
                  type: "error",
                  message: "你未在考试特殊名单库中，无法报名",
                });
                resolve(false);
              }
              resolve(true);
            });
          }
        } else {
          resolve(true);
        }
      });
    },
    signUp(index) {
      let data = this.examList[index];
      this.chooseIndex = index;
      console.log(this.userInfo.zpzt, this.userInfo.zpzt, data.zpysyq)
      if (
        (this.userInfo.zpzt == 0 || !this.userInfo.zpzt) &&
        data.zpysyq != 4
      ) {
        this.$message({
          type: "error",
          message: "照片信息未完善，请先去处理照片信息",
        });
        if (data.xlzdsh != 2) {
          this.$router.push({
            path: "/personalImage",
            query: { show: "zp,xl" },
          });
        } else {
          this.$router.push({ path: "/personalImage", query: { show: "zp" } });
        }
        return;
      }
      if (
        (this.userInfo.xlzt == 0 || !this.userInfo.xlzt) &&
        data.xlzdsh != 2
      ) {
        this.$message({
          type: "error",
          message: "学历信息未上传，请先上传学历信息",
        });
        if (data.zpysyq != 4) {
          this.$router.push({
            path: "/personalEducation",
            query: { show: "zp,xl" },
          });
        } else {
          this.$router.push({
            path: "/personalEducation",
            query: { show: "xl" },
          });
        }

        return;
      }

      //判断是否允许该证件类型报名
      if (
        data.yxbmzjlx &&
        !data.yxbmzjlx.split(",").filter((v) => {
          return v == this.userInfo.sfzjlxm;
        })[0]
      ) {
        getDictionary("dict_sfzjlx").then((res) => {
          let list = res["dict_sfzjlx"].filter((v) => {
            return data.yxbmzjlx.includes(v.value);
          });
          let str = "";
          list.forEach((v) => {
            str += v.name + "、";
          });
          str = str.slice(0, str.length - 1);
          this.$message({
            type: "error",
            message: "该考试只允许以" + str + "注册的用户报名",
          });
        });
        return;
      }

      //跳转页面
      let ksbmbh = data.ksbmbh;
      let record = this.recordList.filter((item) => {
        return item.ksbmbh == ksbmbh;
      })[0];

      // 已有报名数据
      if (record) {
        // 未提交且未审核
        if ((record.zgshzt == 0 || record.zgshzt == null) && !record.sfzstj) {
          this.$router.push({
            path: "./enrollForm",
            query: {
              ksbmbh: ksbmbh,
              bmh: record.bmh,
            },
          });
        } else {
          // return
          this.$router.push({
            path: "./record",
            query: {
              ksbmbh: ksbmbh,
              bmh: record.bmh,
            },
          });
          this.$message({
            type: "error",
            message: "已有报名记录",
          });
          return;
        }
      } else {
        //查看是否在特殊名单库中
        this.checkSpecial(data).then((res) => {
          if (res) {
            if (data.sfqyzymdk) {
              this.exclusiveCheck(ksbmbh, (res) => {
                if (res) {
                  this.qualification(ksbmbh).then((status) => {
                    if (status) {
                      this.$router.push({
                        path: "/agreement",
                        query: {
                          ksbmbh: ksbmbh,
                        },
                      });
                    }
                  });
                } else {
                  this.$message({
                    type: "error",
                    message: "你未在考试专有名单库中，无法报名",
                  });
                }
              });
            } else {
              this.qualification(ksbmbh).then((status) => {
                if (status) {
                  this.$router.push({
                    path: "/agreement",
                    query: {
                      ksbmbh: ksbmbh,
                    },
                  });
                }
              });
            }
          }
        });
      }
    },

    getExam() {
      getExamList(this.postData).then((res) => {
        if (res.status) {
          let data = res.data.sort((x, y) => {
            return (
              new Date(y.createTime.replace(/-/g, "/")).getTime() -
              new Date(x.createTime.replace(/-/g, "/")).getTime()
            );
          });
          // res.data.forEach((item) => {
          //   item.bmkssj = item.bmkssj
          //     ? format(item.bmkssj, "yyyy-MM-dd HH:mm")
          //     : item.bmkssj;
          //   item.bmjssj = item.bmjssj
          //     ? format(item.bmjssj, "yyyy-MM-dd HH:mm")
          //     : item.bmjssj;
          //   item.jfkssj = item.jfkssj
          //     ? format(item.jfkssj, "yyyy-MM-dd HH:mm")
          //     : item.jfkssj;
          //   item.jfjssj = item.jfjssj
          //     ? format(item.jfjssj, "yyyy-MM-dd HH:mm")
          //     : item.jfjssj;
          // });
          if(data.some(x=>x.sfqytsmdk)){
            this.getSpecial().then(() => {
              this.examList = this.examStatus(data);
            })
          }else{
            this.examList = this.examStatus(data);
          }
        }
      });
    },
    examStatus(data) {
      let date = new Date().getTime();
      data.forEach((item) => {
        let kssj = item.bmkssj ? item.bmkssj.replace(/-/g, "/") : "";
        let jssj = item.bmjssj ? item.bmjssj.replace(/-/g, "/") : "";
        kssj = kssj ? new Date(kssj).getTime() : 0;
        jssj = jssj ? new Date(jssj).getTime() : 0;
        if (date < kssj) {
          item.ksbmzt_ = "未开始";
        } else if (kssj < date && date < jssj) {
          item.ksbmzt_ = "正在报名";
        } else {
          item.ksbmzt_ = "已结束";
        }
        if (item.sfqytsmdk) {
          item.special = true;
        }
      });
      //如果存在特殊名单的考试 单独判断考生 是否在 该考试特殊名单里面
      if(data.some(x=>x.special)){
        data.forEach(item=>{
          if (item.sfqytsmdk) {
            let specialData = this.specialList.filter((v) => {
              return v.ksbmbh == item.ksbmbh;
            })[0];
            if (!specialData) {
              item.notshow = 1;//设置不显示
            }
          }
        })
      }
      return data.filter(x=>x.notshow!=1);
    },
    getUserInfo() {
      getUserInfo().then((data) => {
        this.userInfo = data;
        return;
      });
    },
    init() {
      let year = new Date().getFullYear();
      this.nfList = [year, year - 1, year - 2];
      this.postData.ksnf = year;
      this.getExam();
      this.getUserInfo();
      this.getUserExam();
    },
  },
  created() {
    this.init();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px">
          <div class="card-body">
            <h4 class="card-title pb-3 border-dash">
              <span class="mr-2">以下是为系统报名项目</span>
              <el-select
                v-model="postData.ksnf"
                placeholder="请选择考试发布年份"
                class="selectClass"
                @change="getExam"
                size="small"
              >
                <el-option
                  v-for="item in nfList"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </h4>
            <div class="table-responsive border mt-3">
              <table
                class="table table-has-bg light-table table-hover table-bordered"
              >
                <thead class="thead-light">
                  <tr>
                    <th style="width: 3%">序号</th>
                    <th>考试名称</th>
                    <th style="width: 25%">报名起止时间</th>
                    <th style="width: 25%">缴费时间</th>
                    <th style="width: 5%">报名状态</th>
                    <th style="width: 5%">查看</th>
                    <th style="width: 10%">操作</th>
                  </tr>
                </thead>
                <tbody v-if="examList.length != 0">
                  <tr v-for="(item, index) in examList" :key="index">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>
                      <a
                        :href="
                          item.bmtzh
                            ? '/announcementDetails?newsId=' + item.bmtzh
                            : 'javascript:void(0);'
                        "
                        target="_bank"
                        class="blue-font"
                        >{{ item.ksmc }}</a
                      >
                    </td>
                    <td>
                      {{ item.bmkssj }} <span class="m-1">至</span
                      >{{ item.bmjssj }}
                    </td>
                    <td>
                      {{ item.jfkssj
                      }}<span class="m-1">{{ item.jfjssj ? "至" : "" }}</span
                      >{{ item.jfjssj }}
                    </td>
                    <td>{{ item.ksbmzt_ }}</td>
                    <td>
                      <button type="button" class="btn btn-info h30 w-xs">
                        <a
                          :href="
                            item.bmtzh
                              ? '/announcementDetails?newsId=' + item.bmtzh
                              : 'javascript:void(0);'
                          "
                          target="_bank"
                          style="color: white"
                          >公告</a
                        >
                      </button>
                    </td>
                    <td
                      v-if="item.ksbmzt_ != '正在报名' && !item.special"
                      class="table-btn"
                    >
                      <button type="button" class="btn btn-danger h30">
                        无法操作
                      </button>
                    </td>
                    <td
                      v-if="item.ksbmzt_ == '正在报名' || item.special"
                      class="table-btn"
                      :index="index"
                    >
                      <button
                        type="button"
                        class="btn btn-info h30"
                        @click="signUp(index)"
                      >
                        <i class="iconfont icon-come-l mr-1"></i>立即报名
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="text-center" v-if="examList.length == 0">
              无可报名考试
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
